import React, { FC, useCallback, useEffect, useState } from "react"
import {
    LiveBookingGetResponse,
    SuccessResponse,
} from "@/lib/aspida/api/@types"
import { Head } from "@/components/Head"
import { GlobalLayout } from "@/components/GlobalLayout"
import { getFormattedLiveTitle } from "@/components/pages/LiveBooking/LiveBooking.component"
import { Link, navigate } from "gatsby"
import { useApiRequest } from "@/hooks/api/useApiRequest"
import { client } from "@/lib/aspida/client"
import { CircularProgress } from "@mui/material"
import { FormInput } from "@/components/form/FormInput"
import { getTokenFromQueryParam } from "@/components/pages/LiveBooking/url"
import { FormSelect } from "@/components/form/FormSelect"
import { createLiveBookingEditUrl } from "@/components/pages/LiveBooking/Edit/url"
import BtnKakuLoadAnime from "@/components/btn/BtnKakuLoadAnime"
import { useForm } from "react-hook-form"

export const LiveBookingCancel: FC = () => {
    const token = getTokenFromQueryParam()
    const liveBookingFetchApi = useApiRequest<LiveBookingGetResponse>()
    const liveBookingCancelApi = useApiRequest<SuccessResponse>()
    const [isDisabled, setIsDisabled] = useState<boolean>()
    const { handleSubmit } = useForm<{}>()

    useEffect(() => {
        if (!token) {
            navigate("/")
            return
        }

        liveBookingFetchApi.request(
            async () => {
                const result = await client.v1.live_booking._token(token).get()
                setIsDisabled(false)
                return result
            },
            {
                onFailure: () => {
                    setIsDisabled(true)
                },
            }
        )
    }, [])

    /**
     * 送信ボタンを押した際のハンドリング
     */
    const onSubmit = useCallback(() => {
        if (!confirm("内容を送信します。\nよろしいですか？")) {
            return
        }

        liveBookingCancelApi.request(
            async () => {
                return client.v1.live_booking.cancel.post({
                    body: { token: token! },
                })
            },
            {
                onSuccess: () => {
                    // リダイレクト
                    navigate("/live-booking/cancel/send", {
                        state: {
                            toAddress:
                                liveBookingFetchApi.apiRequestState.response!
                                    .liveBooking.toAddress,
                        },
                    })
                },
                onFailure: (e) => {
                    if (e.status === 500 || !e.message) {
                        alert(
                            "ただいまメールをお送りすることができません。\n申し訳ございませんが、しばらく時間がたった後再度送信の程よろしくお願いいたします。"
                        )
                        return
                    }
                    alert(e.message)
                },
            }
        )
    }, [liveBookingFetchApi, liveBookingCancelApi])

    return (
        <GlobalLayout
            Head={<Head title="チケット予約キャンセルフォーム" noIndex />}
        >
            <section className="p-section u-mb-100px">
                <h2 className="p-section__title u-mb-50px">
                    チケット予約キャンセルフォーム
                    <p className="p-section__sub-title">
                        内容確認の上、送信をお願いします
                    </p>
                </h2>
                {liveBookingFetchApi.apiRequestState.isLoading ? (
                    <CircularProgress />
                ) : liveBookingFetchApi.apiRequestState.error ? (
                    <p>
                        予約情報を取得できませんでした。キャンセル処理済みの可能性があります。
                    </p>
                ) : liveBookingFetchApi.apiRequestState.response ? (
                    <form
                        onSubmit={handleSubmit(onSubmit)}
                        className="p-form u-mr-3 u-ml-3 p-section__content"
                    >
                        <div className={"mb-11 text-sm text-gray-500"}>
                            ※予約内容の編集を行いたい場合は
                            <Link
                                to={createLiveBookingEditUrl({
                                    token: token!,
                                })}
                                className={"text-sky-600"}
                            >
                                こちら
                            </Link>
                        </div>
                        <FormInput
                            value={getFormattedLiveTitle({
                                ...liveBookingFetchApi.apiRequestState.response
                                    .live,
                            })}
                            className={`u-width-100 u-mb-10 rsm:text-xs`}
                            disabled={true}
                            label={"ライブ情報"}
                            name={"slug"}
                        />
                        <FormInput
                            value={
                                liveBookingFetchApi.apiRequestState.response
                                    .liveBooking.toAddress
                            }
                            className={`u-width-100 u-mb-10 rsm:text-xs`}
                            disabled={true}
                            label={"メールアドレス"}
                            name={"toAddress"}
                        />
                        <FormSelect
                            value={
                                liveBookingFetchApi.apiRequestState.response
                                    .liveBooking.ticketCount
                            }
                            options={[
                                {
                                    label: String(
                                        liveBookingFetchApi.apiRequestState
                                            .response.liveBooking.ticketCount
                                    ),
                                    value: liveBookingFetchApi.apiRequestState
                                        .response.liveBooking.ticketCount,
                                },
                            ]}
                            label={"チケット枚数"}
                            name={"ticketCount"}
                            className={`u-width-40 u-mb-10 rsm:text-xs`}
                            disabled={true}
                        />
                        <FormInput
                            value={
                                liveBookingFetchApi.apiRequestState.response
                                    .liveBooking.name
                            }
                            className={`u-width-100 u-mb-10 rsm:text-xs`}
                            disabled={true}
                            label={"お名前"}
                            name={"name"}
                        />
                        <FormInput
                            value={
                                liveBookingFetchApi.apiRequestState.response
                                    .liveBooking.otherRequest
                            }
                            className={`u-width-100 u-mb-10 rsm:text-xs`}
                            disabled={true}
                            multiline={true}
                            label={"その他ご要望"}
                            name={"otherRequest"}
                        />

                        <div className="p-form__submit">
                            <BtnKakuLoadAnime
                                link="#"
                                type="submit"
                                isActive={
                                    liveBookingCancelApi.apiRequestState
                                        .isLoading
                                }
                                disabled={isDisabled}
                            >
                                送信する
                            </BtnKakuLoadAnime>
                        </div>
                    </form>
                ) : (
                    <></>
                )}
            </section>
        </GlobalLayout>
    )
}
